export default {
  notifyPickingData:{props:{}},
  npdid:"",
  notifyDeliveryData:{},
  nddid:"",
  notifyFetchingData:{props:{}},
  nfdid:"",
  notifyModalPicking:false,
  notifyModalDelivery:false,
  notifyModalFetching:false,
}
  