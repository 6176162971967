export default {
  getQuickStats: state =>  {
    return state.quickStats
  },
  getOrderaverage: state =>  {
    return state.orderAverage
  },
  getTopProducts: state => {
    return state.topProducts
  },

  getLowStockMonitor: state => {
    return state.lowStockMonitor
  },

  getActionable: state => {
    return state.actionable
  },

  getManufactureQuickStats: state => {
    return state.manufactureQuickStats
  },

  getManufactureOEE: state => {
    return state.manufactureOEE
  },

  getManufactureTopProduction: state => {
    return state.manufactureTopProduction
  },

  getManufactureAverage: state => {
    return state.manufactureAverage
  },

  getManufactureActionable: state => {
    return state.manufactureActionable
  },
}
