import axios from '@/axios.js'

export default {
  updateNotify:({commit},payload)=>{
    return new Promise((resolve,reject)=>{
      axios
      .put('/manufacture/master-data/notify',payload)
      .then(res => {
        commit('GET_PICKING_DATA',res.data.data)
      })
      .catch(err => console.error(err))
    })
  },
  notifyPickingStart: ({ commit }) => {
    var id = setInterval(function(){
      axios
      .get('/manufacture/master-data/notify-fetching')
      .then(res => {
        commit('GET_PICKING_DATA',res.data.data)
      })
      .catch(err => console.error(err))
    },2500)
    commit('SET_INTERVAL_PICKING',id);
  },
  notifyDeliveryStart: ({ commit }) => {
    var id = setInterval(function(){
      axios
      .get('/manufacture/master-data/notify-delivery')
      .then(res => {
      })
      .catch(err => console.error(err))
    },2500)
    commit('SET_INTERVAL_DELIVERY',id);
  },
  notifyFetchingStart: ({ commit }) => {
    var id = setInterval(function(){
      axios
      .get('/manufacture/master-data/notify-reject-fetching')
      .then(res => {
        // console.info('res notify fetching',res)
        commit('GET_FETCHING_DATA',res.data.data)
      })
      .catch(err => console.error(err))
    },2500)
    commit('SET_INTERVAL_FETCHING',id);
  },
  setModalPicking:({commit},payload)=>{
    commit('SET_MODAL_PICKING',payload);
  },
  setModalDelivery:({commit},payload)=>{
    commit('SET_MODAL_DELIVERY',payload);
  },
  setModalFetching:({commit},payload)=>{
    commit('SET_MODAL_FETCHING',payload);
  },
}