import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// Common Modules
import moduleApplication from './application/moduleApplication'
import moduleAuth from './auth/moduleAuth'
import moduleItem from './item/moduleItem'
import moduleRole from './role/moduleRole'
import moduleUser from './user/moduleUser'
import moduleNote from './note/moduleNote'
import moduleDashboard from './dashboard/moduleDashboard'
import moduleMachineDashboard from './machineDashboard/index'
import modulePlant from './plant/index'
import moduleInstructure from './planning/index'
import moduleJob from './joborder/index'
import moduleFetch from './fetch/index'
import moduleNotify from './notify/moduleNotify'
import bom from './bom'
import moduleOrder from './order/moduleOrder'
import modulePicklist from './picklist/index'
import moduleDelivery from './delivery/index'
import modulePauseJob from "./pause/index";
import moduleRequest from './request/moduleRequest'
import execS7 from './executor/s7-protocol'
import moduleSync from "./syncModule/index";

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    app,
    appConfig,
    verticalMenu,
    bom,

    // Common
    application: moduleApplication,
    auth: moduleAuth,
    item: moduleItem,
    role: moduleRole,
    user: moduleUser,
    note: moduleNote,
    dashboard: moduleDashboard,
    machineDashboard: moduleMachineDashboard,
    plant: modulePlant,
    order: moduleOrder,
    picklist: modulePicklist,
    delivery: moduleDelivery,
    request: moduleRequest,
    planning: moduleInstructure,
    joborder: moduleJob,
    fetch: moduleFetch,
    executorS7: execS7,
    pause: modulePauseJob,
    notify:moduleNotify
  },
  strict: process.env.DEV,
});
