import axios from "@/axios.js";
import { getApp,  } from "../../utils/utils";

export default {
  namespaced: true,
  state: {
    pausejob: [],
    pausejobs: [],
    newPauseRespon: "",
    pausejobsetting: [],
  },
  getters: {
    getPauseJobs: (state) => {
      return state.pausejobs;
    },
    newPauseRespon: (state) => {
      return state.newPauseRespon;
    },
    getPauseSetting: (state) => {
      return state.pausejobsetting;
    },
  },
  mutations: {
    GET_PAUSEJOBS(state, payload) {
      state.pausejobs = payload;
    },
    ADD_PAUSEJOB(state, payload) {
      state.pausejob.push(payload);
    },
    NEW_PAUSE_RESPON(state, payload) {
      state.newPauseRespon = payload;
    },
    UPDATE_PAUSEJOB: (state, payload) => {
      let request = state.pausejobs.find((u) => u._id === payload._id);
    },
    GET_PAUSEJOB_SETTING(state, payload) {
      state.pausejobsetting = payload;
    },
    ADD_PAUSEJOB_SETTING(state, payload) {
      state.pausejobsetting = payload;
    },
    UPDATE_PAUSEJOB_SETTING: (state, payload) => {
      let request = state.pausejobsetting.find((u) => u._id === payload._id);
      console.log(request);
    },
  },
  actions: {
    getPauseJobs({ commit }) {
      return new Promise((resolve,reject)=>{
        axios
        .get("/manufacture/master-data/pauseJob")
        .then((res) => {
          commit("GET_PAUSEJOBS", res.data.data);
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        });
      })
    },
    saveUserPauseJob() {
      return new Promise((resolve,reject)=>{
        axios
        .post("/manufacture/master-data/user-pause-job")
        .then((res) => {
          console.log(res.data.data);
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        });
      })
    },
    getActivePauseJob({ commit }, payload) {
      return new Promise((resolve,reject)=>{
        axios
        .get("/manufacture/master-data/active-pause-job", {
          params: {
            username: payload.username,
            email: payload.email,
          }
        })
        .then((res) => {
          resolve(res.data)
        })
        .catch((err) => {
          console.log(err);
          // reject(err)
        });
      })
    },
    addPauseJob({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/pauseJob", payload)
          .then((res) => {
            resolve(res.data.data);
          })
          .catch((err) => reject(err));
      });
    },
    updatePauseJob({ commit }, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/pauseJob/${id}`, payload)
          .then((res) => {
            commit("UPDATE_PAUSEJOB", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    savePauseJob({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/manufacture/master-data/save-pause-job`, payload)
          .then((res) => {
            // commit("UPDATE_PAUSEJOB", res.data.data);
            resolve(res);
          })
          .catch((err) => reject(err));
      });
    },
    getPauseJobSetting({ commit }) {
      return new Promise((resolve,reject)=>{
        axios
        .get("/manufacture/master-data/settingPauseJob")
        .then((res) => {
          commit("GET_PAUSEJOB_SETTING", res.data.data);
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        });
      })
      
    },
    addPauseJobSetting({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/settingPauseJob", payload)
          .then((res) => {
            commit("ADD_PAUSEJOB_SETTING", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    updatePauseJobSet({ commit }, { id, payload }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/settingPauseJob/${id}`, payload)
          .then((res) => {
            commit("UPDATE_PAUSEJOB_SETTING", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
  },
};
