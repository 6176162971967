import axios from "@/axios";
export default {
    namespaced : true,
    state : {
        fetch : [],
        ongoingfetch: [],
        completedfetch: [],
        fetchId : ""
    },
    getters : {
        getFetch : state => {
            return state.fetch;
        },
        getOngoingFetch : state => {
            return state.ongoingfetch;
        },
        getCompletedFetch : state => {
            return state.completedfetch;
        },
        getFetchId : state => {
            return state.fetchId
        }
    },
    actions : {
        getFetch({commit}, params) {
            return new Promise((resolve,reject) => {
                axios.get('manufacture/master-data/fetching', {
                    params: params
                })
                .then((res) => {
                    commit('GET_FETCH', res.data.data)
                    resolve(res.data.metadata)
                }).catch((err) => {
                    console.log({err})
                    reject(err)
                })
            })  
        },
        getOngoingFetch({commit}, params) {
            return new Promise((resolve,reject) => {
                axios.get('manufacture/master-data/ongoing-fetching', {
                    params: params
                })
                .then((res) => {
                    console.log(res)
                    commit('GET_ONGOINGFETCH', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    console.log({err})
                    reject(err)
                })
            })  
        },
        getCompletedFetch({commit}, params) {
            return new Promise((resolve,reject) => {
                axios.get('manufacture/master-data/completed-fetching', {
                    params: params
                })
                .then((res) => {
                    console.log(res)
                    commit('GET_COMPLETEDFETCH', res.data.data)
                    resolve(res.data)
                }).catch((err) => {
                    console.log({err})
                    reject(err)
                })
            })  
        },
        getFetchId({ commit }, {id}){
            return axios.get(`manufacture/master-data/fetching/${id}`)
            .then((response) => {
                let { data } = response.data;
                commit('GET_FETCHID', data); 
            }).catch((e) => {
                console.log(e);
            });
        },
        getFetchDetailId({ commit }, {id}){
            return new Promise ((resolve, reject) => {
            axios.get(`manufacture/master-data/view-fetching/${id}`)
            .then((response) => {
                resolve (response.data.data)
            }).catch((e) => {
                reject(e)
                console.log(e);
            });
          })
        },
        updateFetch: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            axios
              .put(`/manufacture/master-data/fetching/${id}`, data)
              .then((res) => {
                // commit("UPDATE_FETCH", res.data);
                resolve();
              })
              .catch((err) => reject(new Error(err)));
          }),
        confirmJobType: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            axios
              .put(`/manufacture/master-data/fetching/confirm-job-type/${id}`, data)
              .then((res) => {
                // commit("UPDATE_FETCH", res.data);
                resolve();
              })
              .catch((err) => reject(new Error(err)));
          }),
        startPickDrum: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            axios
              .put(`/manufacture/master-data/fetching-start-pick-drum/${id}`, data)
              .then((res) => {
                resolve();
              })
              .catch((err) => reject(new Error(err)));
          }),
        finishPickDrum: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            axios
              .put(`/manufacture/master-data/fetching-finish-pick-drum/${id}`, data)
              .then((res) => {
                // commit("UPDATE_FETCH", res.data);
                resolve();
              })
              .catch((err) => reject(new Error(err)));
          }),
        finishPickBabyDrum: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            axios
              .put(`/manufacture/master-data/fetching-finish-pick-baby-drum/${id}`, data)
              .then((res) => {
                // commit("UPDATE_FETCH", res.data);
                resolve();
              })
              .catch((err) => reject(new Error(err)));
            }),
        waitingMachine: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            console.log("masuk", id)
            axios
              .put(`/manufacture/master-data/fetching/waiting-machine/${id}`, data)
              .then((res) => {
                // commit("UPDATE_FETCH", res.data);
                resolve();
              })
              .catch((err) => reject(new Error(err)));
          }),
        addFetch({commit}, payload) {
            return new Promise((resolve, reject) => {
                axios.post('manufacture/master-data/fetching', payload).then(res => {
                    // commit('ADD_FETCH', res.data);
                    resolve(res.data.data)
                }).catch(err => {
                    console.log({err})
                    reject(err)
                })
            })
        },
        deleteFetch({commit} , { id, data }){
            return new Promise((resolve,reject) => {
                axios.delete('manufacture/master-data/fetching/' + id).then(res => {
                    resolve(res.data.data)
                    commit("DELETE_FETCH", id)
                }).catch(err => {
                    reject(err)
                })
            })
        },
        selectMachine: ({ commit }, payload) => new Promise((resolve, reject) => {
            axios
            .post('manufacture/master-data/fetching/select-machine', payload)
            .then(res => {
                // commit('SELECT_MACHINE', res.data.data)
                resolve(res.data)
            })
            .catch(err => reject(err))
        }),
        changeStatus: ({ commit }, { id, data }) =>
          new Promise((resolve, reject) => {
            axios
              .put(`/manufacture/master-data/fetching/change-status/${id}`, data)
              .then((res) => {
                // commit("UPDATE_FETCH", res.data);
                resolve();
              })
              .catch((err) => reject(err));
          }),
    },
    mutations : {
        GET_FETCH(state, payload){
            state.fetch = payload
        },
        GET_ONGOINGFETCH(state, payload){
            state.ongoingfetch = payload
        },
        GET_COMPLETEDFETCH(state, payload){
            state.completedfetch = payload
        },
        GET_FETCHID(state, payload){
            state.fetchId = payload
        },
        ADD_FETCH(state, payload){
            state.fetch = payload
        },
        SELECT_MACHINE(state, payload){
            state.fetch = payload
        },
        DELETE_FETCH(state, payload){
            let index = state.fetch.findIndex(el => el.id === payload);
            state.fetch.splice(index,1);
        },
        UPDATE_FETCH: (state, payload) => {
        let request = state.fetch.find((u) => u._id === payload._id);
            for (let [key, value] of Object.entries(payload)) {
                if (request[key] && request[key] !== value) {
                request[key] = value;
                }
            }
        },
    }
}