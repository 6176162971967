import axios from '@/axios.js'
import {getApp} from '../../utils/utils';

export default {
  namespaced: true,
  state: {
    picklists: [],
    ongoingPacking: [],
    completedPacking: [],
    pickablePicklists:[],
    pickListRecoil:[],
    updateJobOrderPicklists:[],
    picklistsDrumpicking:[],
    picklist: null,
    picklistId: "",
  },
  getters: {
    getPicklist: (state) => {
      return state.picklists;
    },
    getOngoingPacking: (state) => {
      return state.ongoingPacking;
    },
    getCompletedPacking: (state) => {
      return state.completedPacking;
    },
    getPickListRecoil: (state) => {
      return state.pickListRecoil;
    },
    getPickablePicklist: (state) => {
      return state.pickablePicklists;
    },
    getUpdatePicklistJobOrder: (state) => {
      return state.updateJobOrderPicklists;
    },
    getPicklistDrumpicking: (state) => {
      return state.picklistsDrumpicking;
    },
    getPicklistID: (state) => {
      return state.picklist;
    },
    getPicklistId: (state) => {
      return state.picklistId;
    },
  },
  mutations: {
    GET_PICKABLE_PICKLIST(state, payload) {
      state.pickablePicklists = payload;
    },
    GET_UPDATE_PICKLIST_JOBORDER(state, payload) {
      state.updateJobOrderPicklists = payload;
    },
    GET_PICKLIST(state, payload) {
      state.picklists = payload;
    },
    GET_ONGOING_PACKING(state, payload) {
      state.ongoingPacking = payload;
    },
    GET_COMPLETED_PACKING(state, payload) {
      state.completedPacking = payload;
    },
    GET_PICKLIST_RECOIL(state, payload) {
      state.pickListRecoil = payload;
    },
    GET_PICKLISTDRUMPICKING(state, payload) {
      state.picklistsDrumpicking = payload;
    },
    GET_PICKLISTID(state, payload) {
      state.picklistId = payload;
    },
    GET_PICKLIST_ID(state, payload) {
      state.picklist = payload;
    },
    ADD_PICKLIST(state, payload) {
      state.picklists.push(payload);
    },
    UPLOAD_RECOIL(state, payload) {
      state.picklists.push(payload);
    },
    UPDATE_PICKLIST: (state, payload) => {
      let request = state.picklists.find((u) => u._id === payload._id);
    },
    EMPTY_PICKLIST: (state) => {
      state.picklists = []
    }

  },
  actions: {
    getPickablePicklist({ commit }, params) {
      // console.log('app-name ',process.env.VUE_APP_APPLICATION_NAME)
      return new Promise((resolve, reject) => {
      axios
        .get("/manufacture/master-data/pickable-picklist?props[app]=" + getApp(), {params : params})
        .then((res) => {
          commit("GET_PICKABLE_PICKLIST", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          alert(err);
          reject(err)
        });
      });
    },
    getUpdatePicklistJobOrder({ commit }, params) {
      return new Promise((resolve, reject) => {
      axios
        .get("/manufacture/master-data/update-picklist-joborder?props[app]=" + getApp(), {params : params})
        .then((res) => {
          commit("GET_UPDATE_PICKLIST_JOBORDER", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          alert(err);
          reject(err)
        });
      });
    },
    //Used to get all data
    getPicklist({ commit }, params) {
      // console.log('app-name ',process.env.VUE_APP_APPLICATION_NAME)
      return new Promise((resolve, reject) => {
      axios
        .get("/manufacture/master-data/picklist?props[app]=" + getApp(), {params : params})
        .then((res) => {
          commit("GET_PICKLIST", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          alert(err);
          reject(err)
        });
      });
    },
    getOngoingPacking({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/ongoing-packing", {
            params: params
          })
          .then((res) => {
            commit("GET_ONGOING_PACKING", res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getCompletedPacking({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/completed-packing", {
            params: params
          })
          .then((res) => {
            commit("GET_COMPLETED_PACKING", res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPickListRecoil({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/picklist-recoil", {
            params: params
          })
          .then((res) => {
            commit("GET_PICKLIST_RECOIL", res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getPicklistDrumpicking({ commit }, params) {
      // console.log('app-name ',process.env.VUE_APP_APPLICATION_NAME)
      return new Promise((resolve, reject) => {
      axios
        .get("/manufacture/master-data/drum-picking-picklist?props[app]=" + getApp(),)
        .then((res) => {
          console.log(res)
          commit("GET_PICKLISTDRUMPICKING", res.data.data);
          resolve(res.data.data);
        })
        .catch((err) => {
          alert(err);
          reject(err)
        });
      });
    },
    getPicklistId({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`manufacture/master-data/picklist/${id}`)
          .then((response) => {
            let { data } = response.data;
            commit("GET_PICKLISTID", data);
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    addPicklist({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/picklist", payload)
          .then((res) => {
            commit("ADD_PICKLIST", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    uploadRecoil({ commit }, payload) {
      console.log(payload);
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/recoil", payload)
          .then((res) => {
            commit("UPLOAD_RECOIL", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      });
    },
    updatePicklist({ commit }, { id, data }) {
      var dataToSend = data

      if(dataToSend.props==null||dataToSend.props==undefined){
        dataToSend.props={}
      }
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/picklist/${id}`, dataToSend)
          .then((res) => {
            commit("UPDATE_PICKLIST", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      })

    },
      
    getPicklistByPicklistNo({ commit }, { picklistNo }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/manufacture/master-data/picklistNo/" + picklistNo)
          .then((res) => {
            commit("GET_PICKLIST_ID", res.data.data);
            resolve(res.data.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    pickListTimestamp({ commit }, { id, timestamp }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/picklist/timestamp/${id}`, {
            timestamp: timestamp,
          })
          .then((response) => {
            let { data } = response.data;
            commit("GET_PICKLISTID", data);
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    pickListTimestampMany({ commit }, { id, timestamp }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`manufacture/master-data/picklist/timestamp-many`, {
            timestamp: timestamp,
            id: id
          })
          .then((response) => {
            let { data } = response.data;
            commit("GET_PICKLISTID", data);
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
            reject(e)
          });
      });
    },
    updatePicklistProps({ commit }, { payload }) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/picklist/props/", payload)
          .then((res) => {
            resolve();
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    resetPicklist({ commit }) {
      commit("EMPTY_PICKLIST");
    },
    startBabyDrumSetup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/start-baby-drum-setup", payload)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    finishBabyDrumSetup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/finish-baby-drum-setup", payload)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    startMachineHMIProcess({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/start-machine-process/${ id }` )
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    stopMachineHMIProcess({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/stop-machine-process/${ id }` )
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    restartMachineHMIProcess({ commit }, { id , payload}) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/restart-machine-process/${ id }`, payload)
          .then((res) => {
            console.log("done restart");
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    startPacking: ({ commit }, { id }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/start-packing-picklist/${id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
    endPacking: ({ commit }, { id }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/end-packing-picklist/${id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
    babyDrumSetup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/baby-drum-setup", payload)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    updateDirectSalesPicklist: ({ commit }, { id, data }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/direct-sales-process/${id}`, data)
          .then((res) => {
            console.log(data);
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
    updateDirectSalesPicklistTimestamp: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post(`/manufacture/master-data/direct-sales-packing-process`, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
    deletePickableUploadRecoilPicklist: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        axios
          .get(`/manufacture/master-data/delete-upload-recoil-pickable-picklist`, payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
  },
};
