import axios from '@/axios.js';


export default {
  namespaced: true,
  state: {
    s7ProtocolVars: [{type:'INT', start: '', area: '', dbnr: '', value: ''}],
    s7ProtocolStatus: [],
  },
  mutations: {
    S7PROTOCOLVARS: (state, payload) => {
      state.s7ProtocolVars=payload
    },
    S7PROTOCOLSTATUS: (state, payload) => {
      // Remove result before
      state.s7ProtocolStatus.splice(0);    
      
      state.s7ProtocolStatus.push(payload);
    },
  },
  actions: {
    getS7ProtocolStatus: ({commit}, payload) => {
      return new Promise((resolve, reject) => {
        if(!payload.action) {
          return reject('Please input action type first!'); 
        }

        if(payload.action === 'READ') {
          payload.value = 0;
        }

        axios
          .post("/actor/executor/s7-protocol", payload)
          .then(s7ProtocolStatusResponse => {
            commit('S7PROTOCOLSTATUS', s7ProtocolStatusResponse.data.result);
            return resolve(payload.action + " Successful");
          })
          .catch(s7ProtocolStatusResponseError => {
            console.info('error ', s7ProtocolStatusResponseError);
            commit('S7PROTOCOLSTATUS', s7ProtocolStatusResponseError);
            return reject(s7ProtocolStatusResponseError || 'Failed');
          });
      });
    },
  },
  getters: {
    s7ProtocolVars: state => {
      return state.s7ProtocolVars;
    },
    s7ProtocolStatus: state => {
      return state.s7ProtocolStatus;
    }
  },
}
