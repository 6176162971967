import state from './moduleRequestState'
import mutations from './moduleRequestMutations'
import actions from './moduleRequestActions'
import getters from './moduleRequestGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
