export default {
  notifyPickingData: (state) => {
    return state.notifyPickingData;
  },
  notifyDeliveryData:(state)=>{
    return state.notifyDeliveryData;
  },
  notifyFetchingData: (state) => {
    return state.notifyFetchingData;
  },
  notifyModalPicking:(state)=>{
    return state.notifyModalPicking
  },
  notifyModalDelivery:(state)=>{
    return state.notifyModalDelivery
  },
  notifyModalFetching:(state)=>{
    return state.notifyModalFetching
  },
}
  