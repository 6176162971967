import axios from "@/axios.js";
import { getApp } from "../../utils/utils";
export default {
  namespaced: true,
  state: {
    hardwares: [],
    stations: [],
    stationID: "",
    reasonlist: [],
  },
  getters: {
    getHardwares: (state) => {
      return state.hardwares;
    },
    getStations: (state) => {
      return state.stations;
    },
    getStationId: (state) => {
      return state.stationID;
    },
    getProductionLines: (state) => {
      var filtered = state.stations.filter((x) => {
        return x.stations.length > 0;
      });
      return filtered;
    },
    hardwareSelectOptions(state) {
      return state.hardwares.map((h) => {
        return Object.assign({}, { value: h.id, text: h.name });
      });
    },
    hardwareSelectValueOptions(state) {
      return state.hardwares.map((h) => {
        return Object.assign({}, { value: h, text: h.name });
      });
    },
    getReasonList: (state) => {
      return state.reasonlist;
    },
  },
  mutations: {
    DELETE_HARDWARE(state, payload) {
      var index = state.hardwares.findIndex((x) => {
        return x.id == payload;
      });
      if (index >= 0) {
        state.hardwares.splice(index, 1);
      }
    },
    DELETE_STATION(state, payload) {
      var index = state.stations.findIndex((x) => {
        return x.id == payload;
      });
      if (index >= 0) {
        state.stations.splice(index, 1);
      }
    },
    GET_STATIONS(state, payload) {
      state.stations = payload;
    },
    GET_STATIONID(state, data) {
      state.stationID = data;
    },
    GET_HARDWARE(state, payload) {
      state.hardwares = payload;
    },
    ADD_HARDWARE(state, payload) {
      state.hardwares.push(payload);
    },
    ADD_STATION(state, payload) {
      state.stations.push(payload);
    },
    EDIT_HARDWARE(state, payload) {
      var editIndex = state.hardwares.findIndex((x) => {
        return x.id == payload.id;
      });
      if (editIndex >= 0) {
        var curHardware = state.hardwares[editIndex];
        //get keys of object in payload
        var objects = Object.keys(payload);
        for (var i = 0; i < objects.length; i++) {
          curHardware[objects[i]] = payload[objects[i]];
        }
      }
    },
    EDIT_STATION(state, payload) {
      var editIndex = state.stations.findIndex((x) => {
        return x.id == payload.id;
      });
      if (editIndex >= 0) {
        var curStation = state.stations[editIndex];
        //get keys of object in payload
        var objects = Object.keys(payload);
        for (var i = 0; i < objects.length; i++) {
          curStation[objects[i]] = payload[objects[i]];
        }
      }
    },
    GET_REASON_LIST(state, payload) {
      state.reasonlist = payload;
    },
    UPDATE_REASON_LIST(state, payload) {
      let request = state.reasonlist.find((u) => u._id === payload._id);
      for (let [key, value] of Object.entries(payload)) {
        if (request[key] && request[key] !== value) {
          request[key] = value;
        }
      }
    },
    NEW_REASONLIST(state, payload) {
      state.reasonList = payload
    }
  },
  actions: {
    //Used to get all item data (component or curHardware)
    getHardwares({ commit }) {
      return axios
        .get("/manufacture/master-data/hardwares?props[app]=" + getApp())
        .then((res) => {
          commit("GET_HARDWARE", res.data.data);
        })
        .catch((err) => {
          alert(err);
        });
    },
    getStations({ commit }) {
      axios
        .get("/manufacture/master-data/stations?props[app]=" + getApp())
        .then((res) => {
          commit("GET_STATIONS", res.data.data);
        })
        .catch((err) => {
          alert(err);
        });
    },
    getStationId: ({ commit }, { id }) => {
      return new Promise((resolve, reject) => {
        axios
          .get(`/manufacture/master-data/station/${id}`)
          .then((res) => {
            commit("GET_STATIONID", res.data.data);
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    addHardware({ commit }, payload) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        var data = {
          name: payload.name,
          ip: payload.ip,
          type: payload.type,
          protocol: payload.protocol,
          port: payload.port,
          props: {
            app: getApp(),
            brand: payload.props.brand,
            model: payload.props.model,
          },
        };

        if (payload.props.station) {
          //expected data {name:station,isNew:true/false}
          data.props.station = payload.props.station;
        }
        axios
          .post("/manufacture/master-data/hardware/add", data)
          .then((res) => {
            commit("ADD_HARDWARE", res.data.data);
            resolve("run finished");
          })
          .catch((err) => {
            if (err.message === "Request failed with status code 400") {
              reject("Invalid Format IP Adress");
            }
            reject(err.message);
          });
      });
    },
    editHardware({ commit }, { id, payload }) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        var data = {
          name: payload.name,
          ip: payload.ip,
          type: payload.type,
          protocol: payload.protocol,
          port: payload.port,
          props: {
            app: getApp(),
            brand: payload.props.brand,
          },
        };
        if (payload.station) {
          //expected data {name:station,isNew:true/false}
          data.props.station = payload.station;
        }
        axios
          .put("/manufacture/master-data/hardware/" + id, data)
          .then((res) => {
            commit("EDIT_HARDWARE", res.data.data);
            resolve("edit bom finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    deleteHardware({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/manufacture/master-data/hardware/" + id)
          .then((res) => {
            commit("DELETE_HARDWARE", id);
            resolve("delete bom finish");
          })
          .catch((err) => {
            console.log(err.errors);
            reject({ message: err.message, errors: err.errors });
          });
      });
    },
    addStation({ commit }, payload) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        var data = {
          name: payload.name,
          hardwares: payload.props.isProductionLine
            ? []
            : payload.hardwares.map((x) => {
                return x.name.id;
              }),
          offsetLength: payload.offsetLength,
          stations: payload.props.isProductionLine ? payload.stations : [],
          props: {
            app: getApp(),
            isProductiosnLine: payload.props.isProductionLine ? true : false,
            offsetLength: payload.props.offsetLength
          },
        };

        axios
          .post("/manufacture/master-data/station/add", data)
          .then((res) => {
            commit("ADD_STATION", res.data.data);
            resolve("run finished");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    HMISelectStation({ commit }, { id, payload }) {
      return new Promise((resolve, reject) => {
        var data = {
          stations: [],
          props: {
            ...payload.props,
            app: getApp(),
            //isProductionLine: payload.props.isProductionLine ? true : false,
          },
        };

        axios
          .put("/manufacture/master-data/station/" + id, data)
          .then((res) => {
            commit("EDIT_STATION", res.data.data);
            resolve("edit station finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    editStation({ commit }, { id, payload }) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        var data = {
          name: payload.name,
          hardwares: payload.props.isProductionLine
            ? []
            : payload.hardwares.map((x) => {
                return x.id;
              }),
          stations: payload.props.isProductionLine ? payload.stations : [],
          offsetLength: payload.offsetLength,
          props: {
            ...payload.props,
            app: getApp(),
            //isProductionLine: payload.props.isProductionLine ? true : false,
          },
        };

        axios
          .put("/manufacture/master-data/station/" + id, data)
          .then((res) => {
            commit("EDIT_STATION", res.data.data);
            resolve("edit station finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    resetStation({ commit }, { id, payload }) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        var data = {
          props: {
            status: "Idle",
            app: getApp(),
            //isProductionLine: payload.props.isProductionLine ? true : false,
          },
        };

        axios
          .put("/manufacture/master-data/station/reset/" + id)
          .then((res) => {
            //commit("EDIT_STATION", res.data.data);
            resolve("reset station finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    deleteStation({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/manufacture/master-data/station/" + id)
          .then((res) => {
            commit("DELETE_STATION", id);
            resolve("delete station finish");
          })
          .catch((err) => {
            reject({ message: err.message, errors: err.errors });
          });
      });
    },
    getReasonList({ commit }) {
      return new Promise((resolve, reject) => {
        axios
        .get("/manufacture/master-data/reasonlists")
        .then((res) => {
          commit("GET_REASON_LIST", res.data.data);
          resolve(res.data.data)
        })
        .catch((err) => {
          reject(err)
        });
      })
    },
    updateReasonList({ commit }, payload) {
      var id = payload.id;
      var data = {
        reasonList: payload.reasonList,
      };

      return axios
        .put("/manufacture/master-data/reasonlists/" + id, data)
        .then((res) => {
          // console.log(res.data.data);
          //console.info("after updated", res.data.data.setType);
          commit("UPDATE_REASON_LIST", res.data.data);
        })
        .catch((err) => {
          alert(err.message);
        });
    },
    updateStation({ commit }, { id, payload }) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        axios
          .put("/manufacture/master-data/station/" + id, payload)
          .then((res) => {
            commit("EDIT_STATION", res.data.data);
            resolve("edit station finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    updateStationProps({ commit }, { id, payload }) {
      //Process payload, optional
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/station/props/", payload)

          .then((res) => {
            commit("EDIT_STATION", res.data.data);
            resolve("edit station finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    resetStation({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put("/manufacture/master-data/station/reset/" + id)

          .then((res) => {
            // commit("EDIT_STATION", res.data.data);
            resolve("edit station finish");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    addNewReasonList({ commit }, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/reasonlists", payload)
          .then((res) => {
            // console.log(res.data.data);
            commit("UPDATE_REASON_LIST", res.data.data);
            resolve("new reason added");
          })
          .catch((err) => {
            reject(err.message);
          });
      });
    },
    stationNextPicklist({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/station/next-picklist/${id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
};
