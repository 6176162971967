<template>
  <div
    id="app"
    class="h-100"
    :class="[skinClasses]"
  >
    <component :is="layout">
      <router-view />
    </component>
    <b-toast
      v-model="notifyModalPicking"
      title="New Drum Request"
      :auto-hide-delay="5000"
      variant="success"
      solid: true
      @shown="onNotifyHide({fetchingID:notifyPickingData.id})"
    >
      New Drum Request: JO Number {{notifyPickingData? notifyPickingData.props.jobOrderNumber : ''}}
    </b-toast>
    <!-- <b-modal 
    @hide="onNotifyHide({orderNumber:notifyDeliveryData._id})" 
    v-model="notifyModalDelivery" 
    title="New Delivery can be Processed" 
    size="md" ok-only >
      New Delivery can be Processed: Order Number {{notifyDeliveryData._id}}
    </b-modal> -->
    <b-modal 
      @hide="onNotifyHide({fetchingID:notifyFetchingData.id})" 
      v-model="notifyModalFetching" 
      title="Rejected Job on Drum Picking" 
      size="md" ok-only >
      Rejected job: JO Number {{notifyFetchingData? notifyFetchingData.props.jobOrderNumber : ''}}
    </b-modal>

  </div>
</template>

<script>

// This will be populated in `beforeCreate` hook
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig'
import { watch } from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import newJob from '@/assets/audio/newJob.mp3'

import { useWindowSize, useCssVar } from '@vueuse/core'

import store from '@/store'

// const LayoutVertical = () => import('@/layouts/vertical/LayoutVertical.vue')
const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue')
const LayoutFull = () => import('@/layouts/full/LayoutFull.vue')

import {
  domainPermission,
  viewAccess,
  userAccess
} from "@/utils/utils.js";



export default {
  components: {

    // Layouts
    LayoutHorizontal,
    // LayoutVertical,
    LayoutFull,

  },
  // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
  // Currently, router.currentRoute is not reactive and doesn't trigger any change
  created(){
    this.$store
      .dispatch('auth/fetchUser')
      .catch(err => {
        console.error(err);
        this.$bvToast.toast("Fetch User Data Error", {
            title: 'Failed',
            variant: 'danger',
            solid: true,
          })
      })
  },
  methods:{
    async onNotifyHide(payload){
      await this.$store.dispatch("notify/updateNotify",payload)
    },
    playNotificationSound() {
      const audioElement = new Audio(newJob)
      audioElement.play();
    },
  },
  mounted(){
    //getting picking & delivery permission to enable notify popup
    var pickingPermission = (userAccess("Ongoing Drum Picking"))?userAccess("Ongoing Drum Picking").notify:false;
    // var deliveryPermission = (userAccess("Delivery Picker"))?userAccess("Delivery Picker").notify:false;
    var fetchingPermission = (userAccess("Ongoing Job Order"))?userAccess("Ongoing Job Order").notify:false;

    //console.info('permission view access ',userPermission);
    if(pickingPermission){
      console.log('masuk pickingPermission');
      this.$store.dispatch("notify/notifyPickingStart")

      this.$watch('notifyModalPicking', (newValue) => {
        if (newValue) {
          this.playNotificationSound();
        }
      });
    }
    // if(deliveryPermission){
    //   console.log('masuk deliveryPermission');
    //   this.$store.dispatch("notify/notifyDeliveryStart")

    //   this.$watch('notifyModalDelivery', (newValue) => {
    //     if (newValue) {
    //       this.playNotificationSound();
    //     }
    //   });
    // }
    if(fetchingPermission){
      console.log('masuk fetchingPermission');
      this.$store.dispatch("notify/notifyFetchingStart")

      this.$watch('notifyModalFetching', (newValue) => {
        if (newValue) {
          this.playNotificationSound();
        }
      });
    }

  },
  computed: {
    notifyModalPicking: {
      get() {
        return this.$store.getters["notify/notifyModalPicking"]
      },
      set(newValue) {
        return this.$store.dispatch("notify/setModalPicking", newValue);
      },
    },
    notifyModalDelivery:{
      get() {
        return this.$store.getters["notify/notifyModalDelivery"]
      },
      set(newValue) {
        return this.$store.dispatch("notify/setModalDelivery", newValue);
      },
    },
    notifyModalFetching: {
      get() {
        return this.$store.getters["notify/notifyModalFetching"]
      },
      set(newValue) {
        return this.$store.dispatch("notify/setModalFetching", newValue);
      },
    },
    notifyPickingData(){
      return this.$store.getters["notify/notifyPickingData"]
    },
    notifyDeliveryData(){
      return this.$store.getters["notify/notifyDeliveryData"]
    },
    notifyFetchingData(){
      return this.$store.getters["notify/notifyFetchingData"]
    },
    layout() {
     //if (this.$route.meta.layout === 'full')
      return 'layout-full'
     // return `layout-${this.contentLayoutType}`
    },
    contentLayoutType() {
      return this.$store.state.appConfig.layout.type
    },
  },
  beforeCreate() {
    // Set colors in theme
    const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = colors.length; i < len; i++) {
      $themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim()
    }

    // Set Theme Breakpoints
    const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl']

    // eslint-disable-next-line no-plusplus
    for (let i = 0, len = breakpoints.length; i < len; i++) {
      $themeBreakpoints[breakpoints[i]] = Number(useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2))
    }

    // Set RTL
    const { isRTL } = $themeConfig.layout
    document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr')
  },
  setup() {
    

    

    const { skin, skinClasses } = useAppConfig()

    // If skin is dark when initialized => Add class to body
    if (skin.value === 'dark') document.body.classList.add('dark-layout')

    // Set Window Width in store
    store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth)
    const { width: windowWidth } = useWindowSize()
    watch(windowWidth, val => {
      store.commit('app/UPDATE_WINDOW_WIDTH', val)
    })

    return {
      skinClasses,
    }
  },
}
</script>