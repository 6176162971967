import axios from '@/axios.js'
import router from '@/router/index.js'

export default {
  fetchRequest: ({ commit }) => {
    new Promise((resolve, reject) => {
      axios
        .get('/commerce/core/orders')
        .then(res => {
          commit('FETCH_REQUEST', res.data.data)
          var filterSales = res.data.data.filter(x=>{
            if(x.props.app){
              return x.props.app === router.app.$session.get('phibase-app') && x.type==='Sales'
            }
          })
          var filterPurchase = res.data.data.filter(x=>{
            if(x.props.app){
              return x.props.app === router.app.$session.get('phibase-app') && x.type==='Purchase'
            }
          })
          commit('FETCH_REQUEST_SALES', filterSales)
          commit('FETCH_REQUEST_PURCHASE', filterPurchase)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  addRequest: ({ commit }, payload) => new Promise((resolve, reject) => {

    payload.item= payload.item.map(x=>{
      return {...x,detail:x.detail.id}
    })

    
    axios
      .post('/commerce/core/orders', payload)
      .then(res => {
        commit('ADD_REQUEST', res.data.data)
        resolve()
      })
      .catch(err => reject(err))
  }),
  updateRequest: ({ commit }, { id, data }) => new Promise((resolve, reject) => {
    axios
      .put(`/commerce/core/orders/${id}`, data)
      .then(res => {
        commit('UPDATE_REQUEST', res.data.data)
        resolve()
      })
      .catch(err => reject(err))
  }),
  deleteRequest: ({ commit }, { id }) => new Promise((resolve, reject) => {
    axios
      .delete(`/commerce/core/orders/${id}`)
      .then(() => {
        commit('DELETE_REQUEST', id)
        resolve()
      })
      .catch(err => reject(err))
  }),
}
