export default {
    FETCH_ORDER_SALES: (state, payload) => {
      state.orderSales = payload
    }, 
    FETCH_ORDER_PURCHASE: (state, payload) => {
      state.orderPurchase = payload
    },
    FETCH_RECOIL: (state, payload) => {
      state.recoil = payload
    },
    FETCH_ORDER: (state, payload) => {
      state.order = payload
    },
    GET_ORDER_ID(state, data) {
      state.orderID = data;
  },
    ADD_ORDER: (state, payload) => {
      state.order.push(payload)
    },
    UPDATE_ORDER: (state, payload) => {
      let order = state.order.find(u => u._id === payload._id)
      for (let [key, value] of Object.entries(payload)) {
        if (order[key] && order[key] !== value) {
          order[key] = value
        }
      }
    },
    DELETE_ORDER: (state, payload) => {
      let i = state.order.findIndex(u => u._id === payload)
      state.order.splice(i, 1)
    }
  }