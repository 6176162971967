
import axios from '@/axios.js'
import {getApp} from '../../utils/utils';
export default {
  namespaced: true,
  state: {
    instructions:[],
    recipes:[],
    workorders:[],
  },
  getters: {
    getInstructions:state=>{
      return state.instructions;
    },
    getRecipes:state=>{
      return state.recipes;
    },
    getWorkOrders:state=>{
      return state.workorders;
    }
  },
  mutations: {
    GET_WORKORDERS(state,payload){
      state.workorders = payload
    },
    ADD_WORKORDER(state,payload) {
      state.workorders.push(payload)
    },
    EDIT_WORKORDER(state,payload){
      var editIndex = state.workorders.findIndex(x=>{return x.id==payload.id})
      if(editIndex>=0){
        var curWO=state.workorders[editIndex];
        //get keys of object in payload
        var objects=Object.keys(payload);
        for(var i=0;i<objects.length;i++){
          curWO[objects[i]]=payload[objects[i]];
        }
      }
    },
    DELETE_WORKORDER(state,payload){
      var index = state.workorders.findIndex(x=>{return x.id==payload});
      if(index>=0){
        state.workorders.splice(index,1);
      }
    },
    GET_RECIPES(state,payload){
      state.recipes = payload
    },
    ADD_RECIPE(state,payload) {
      state.recipes.push(payload)
    },
    EDIT_RECIPE(state,payload){
      var editIndex = state.recipes.findIndex(x=>{return x.id==payload.id})
      if(editIndex>=0){
        var curRecipe=state.recipes[editIndex];
        //get keys of object in payload
        var objects=Object.keys(payload);
        for(var i=0;i<objects.length;i++){
          curRecipe[objects[i]]=payload[objects[i]];
        }
      }
    },
    DELETE_RECIPE(state,payload){
      var index = state.recipes.findIndex(x=>{return x.id==payload});
      if(index>=0){
        state.recipes.splice(index,1);
      }
    },
    GET_INSTRUCTIONS(state,payload) {
      state.instructions = payload
    },
    ADD_INSTRUCTION(state,payload){
      state.instructions.push(payload)
    },
    EDIT_INSTRUCTION(state,payload){
      var editIndex = state.instructions.findIndex(x=>{return x._id == payload.id})
      var curInstruction;
      if(editIndex>=0){
        curInstruction=state.instructions[editIndex];
        //get keys of object in payload
        var objects=Object.keys(payload);
        for(var i=0;i<objects.length;i++){
          curInstruction[objects[i]]=payload[objects[i]];
        }
      }
    },
    
    DELETE_INSTRUCTION(state,payload){
      var index = state.instructions.findIndex(x=>x._id==payload);
      state.instructions.splice(index,1);
      
    },
  },
  actions: {
    //Used to get all data
   getInstructions({ commit }){
      // console.log('app-name ',process.env.VUE_APP_APPLICATION_NAME)
      axios
          .get('/manufacture/master-data/instructions?props[app]='+getApp())
          .then(res => {
            commit('GET_INSTRUCTIONS',res.data.data)
          })
          .catch(err => {
            alert(err);
          });
    },
    addInstruction({commit},payload){
      //Process payload, optional
      return new Promise((resolve,reject)=>{
        var data={
          name:payload.name,
          hardware: payload.hardware.value.id,
          station: payload.station.value.id,
          productionLine: payload.station.id,
          instructionType: payload.instructionType,
          props:{
            ...payload.props,
            app:getApp(),
          },
        }
        console.log(data)
        axios
            .post('/manufacture/master-data/instruction/add',data)
            .then(res => {

              commit('ADD_INSTRUCTION',res.data.data)
              resolve('run finished')
            })
            .catch(err => {
              reject(err.message)
            });
      })
      
      
    },
    editInstruction({commit},{id,payload}){
      return new Promise((resolve,reject)=>{
        var data={
          name:payload.name,
          hardware: payload.hardware.id,
          station: payload.station,
          productionLine: payload.station,
          instructionType: payload.instructionType,
          props:{
            ...payload.props,
            app:getApp(),
          },
        }  
        axios
            .put('/manufacture/master-data/instruction/'+id,data)
            .then(res => {
              commit('EDIT_INSTRUCTION',res.data.data)
              resolve('edit bom finish')
            })
            .catch(err => {
              reject(err.message);
            });
      })
      
    },
    deleteInstruction({commit},id){
      return new Promise((resolve,reject)=>{
        axios
          .delete('/manufacture/master-data/instruction/'+id)
          .then(res => {
            commit('DELETE_INSTRUCTION',id)
            resolve('delete instruction finish')
          })
          .catch(err => {
            console.log(err.errors)
            reject({message:err.message,errors:err.errors});
          });
      })
    
    },
    getRecipes({ commit }){
      axios
          .get('/manufacture/master-data/recipes?props[app]='+getApp())
          .then(res => {
            commit('GET_RECIPES',res.data.data)
          })
          .catch(err => {
            alert(err);
          });
    },
    addRecipe({commit},payload){
      //Process payload, optional
      return new Promise((resolve,reject)=>{
      var data={
        name:payload.name,
        item:payload.item,
        instructions:payload.instructions.map(x=>{return x.instruction.id}),
        props:{
          app:getApp(),
        },
      }
      axios
          .post('/manufacture/master-data/recipe/add',data)
          .then(res => {
            commit('ADD_RECIPE',res.data.data)
            resolve('run finished')
          })
          .catch(err => {
            reject('reject')
            alert(err);
          });
      })
    },
    addMultipleRecipe({commit},payload){
      //Process payload, optional
      return new Promise((resolve,reject)=>{
        var mappedInstructions=payload.instructions.map(x=>{return x.instruction.id});
        var mappedData=payload.items.map((x,index)=>{
          return{
            name:payload.name+"_"+index,
            item:x,
            instructions:mappedInstructions,
            props:{
              app:getApp(),
            },
          }
        })
        axios
          .post('/manufacture/master-data/recipe/add',mappedData)
          .then(res => {
            commit('ADD_RECIPE',res.data.data)
            resolve('run finished')
          })
          .catch(err => {
            reject('reject')
            alert(err);
          });
      })
    },
    editRecipe({commit},{id,payload}){
      //Process payload, optional
      return new Promise((resolve,reject)=>{
        var data={
          name:payload.name,
          item:payload.item,
          instructions:payload.instructions.map(x=>{return x.instruction.id}),
          props:{
            app:getApp(),
          },
        }
        
        axios
            .put('/manufacture/master-data/recipe/'+id,data)
            .then(res => {
              commit('EDIT_RECIPE',res.data.data)
              resolve('edit station finish')
            })
            .catch(err => {
              reject(err);
              alert(err);
            });
      })
      
    },
    deleteRecipe({commit},id){
      return new Promise((resolve,reject)=>{
        axios
          .delete('/manufacture/master-data/recipe/'+id)
          .then(res => {
            commit('DELETE_RECIPE',id)
            resolve('delete recipe finish')
          })
          .catch(err => {
            console.log(err.errors)
            reject({message:err.message,errors:err.errors});
          });
      })
    
    },
    getWorkOrders({ commit }){
      axios
          .get('/manufacture/master-data/workorders?props[app]='+getApp())
          .then(res => {
            commit('GET_WORKORDERS',res.data.data)
          })
          .catch(err => {
            alert(err);
          });
    },
    addWorkOrder({commit},payload){
      //Process payload, optional
      return new Promise((resolve,reject)=>{
        console.log(payload)
        if(payload.recipe){
          var data={
            name:payload.name,
            recipe:payload.recipe,
            code:payload.code,
            props:{
              ...payload.props,
              app:getApp(),
            },
          }
        } else{
          var data={
            name:payload.name,
            code:payload.code,
            props:{
              ...payload.props,
              app:getApp(),
            },
          }
        }

      
      axios
          .post('/manufacture/master-data/workorder/add',data)
          .then(res => {
            commit('ADD_WORKORDER',res.data.data)
            resolve('run finished')
          })
          .catch(err => {
            reject(err.response.data.errors[0].message)
            // alert(err);
          });
      })
    },
    editWorkOrder({commit},{id,payload}){
      //Process payload, optional
      return new Promise((resolve,reject)=>{

        var quantity=(payload.props.quantity)?payload.props.quantity:1;

        var parsedQuantity = (typeof quantity =='number')?quantity: Number(quantity);
        var data={
          name:payload.name,
          recipe:payload.recipe,
          code:payload.code,
          props:{
            ...payload.props,
            itemData:{detail:id,quantity:parsedQuantity},
            app:getApp(),
          },
        }
        axios
          .put('/manufacture/master-data/workorder/'+id,data)
          .then(res => {
            commit('EDIT_WORKORDER',res.data.data)
            resolve('edit workorder finish')
          })
          .catch(err => {
            reject(err.response.data.errors[0].message);
            // alert(err);
          });
      })
    },
    deleteWorkOrder({commit},id){
      return new Promise((resolve,reject)=>{
        axios
          .delete('/manufacture/master-data/workorder/'+id)
          .then(res => {
            commit('DELETE_WORKORDER',id)
            resolve('delete work order finish')
          })
          .catch(err => {
            console.log(err.errors)
            reject({message:err.message,errors:err.errors});
          });
      })
    
    },
    scanWorkOrder: ({ commit }, payload) => {
    
      return new Promise((resolve, reject) => {
        console.log('scan QR',payload)
        axios
          .post('/manufacture/master-data/workorder/scan/'+payload.barcode)
          .then((res) => {
            console.log('200')
            commit('no')
            resolve('');
          }).catch(err => {
            console.log('400');
            reject(err)
          }
            
          );
      });
    },
  }
}
