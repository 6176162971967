import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store/index'
import { domainPermission, viewAccess } from '@/utils/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "",
      component: () => import("../layouts/vertical/LayoutVertical.vue"),
      children: [
        {
          path: "/",
          redirect: "ongoing-job-order",
        },

        // Profile
        {
          path: "/profile",
          name: "profile",
          component: () => import("@/views/Profile.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        // Change Password
        {
          path: "/change-password",
          name: "change-password",
          component: () => import("@/views/ChangePassword.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        // Application
        {
          path: "/application",
          name: "application",
          component: () => import("@/views/Application.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        // Machine Dashboard
        {
          path: "/machine-dashboard",
          name: "machine-dashboard",
          component: () =>
            import("@/views/modules/dashboard/MachineDashboard.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        // Dashboard Manufacture
        {
          path: "/dashboard-manufacture",
          name: "dashboard-manufacture",
          component: () =>
            import("@/views/modules/dashboard/DashboardManufacture.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // Dashboard Commerce
        {
          path: "/dashboard-commerce",
          name: "dashboard-commerce",
          component: () =>
            import("@/views/modules/dashboard/DashboardCommerce.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/dashboard/commerce/chart",
          name: "chart",
          component: () =>
            import("@/views/modules/dashboard/DashboardCommerceChart.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        /**
         * Deprecated - not to be used
         */
        /*
        {
          path: "/component",
          name: "component",
          component: () => import("@/views/item/Component.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        */

        /**
         * Deprecated END
         */
        {
          path: "/baby-drum",
          name: "baby-drum",
          component: () => import("@/views/item/Drum.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // Item

        /**
         * Deprecated - not to be used
         */
        /*
        {
          path: "/cable",
          name: "cable",
          component: () => import("@/views/modules/item/Item.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // purchase
        {
          path: "/purchase",
          name: "purchase",
          component: () => import("@/views/modules/purchase/purchase.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/purchase/add-purchase",
          name: "add-purchase-request",
          component: () => import("@/views/modules/purchase/addPurchase.vue"),
          props: true,
          meta: {
            breadcrumb: [
              { text: "Purchase Request", to: "/purchase-request" },
              { text: "Add Purchase", active: true },
            ],
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/purchase/add-purchase/:id",
          name: "add-purchase-request-fromSales",
          component: () =>
            import("@/views/modules/purchase/addPurchaseRequestID.vue"),
          props: true,
          meta: {
            breadcrumb: [
              { text: "Purchase Request", to: "/purchase-request" },
              { text: "Add Purchase Request", active: true },
            ],
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/purchase-request/add-purchase-request/:id/:itemID/:quantity",
          name: "add-purchase-request-manufacture",
          component: () =>
            import("@/views/modules/purchase/addPurchaseRequestManu.vue"),
          props: true,
          meta: {
            breadcrumb: [
              { text: "Purchase Request", to: "/purchase-request" },
              { text: "Add Purchase Request", active: true },
            ],
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/purchase/edit-purchase/:id",
          name: "edit-purchase-request",
          component: () => import("@/views/modules/purchase/editPurchase.vue"),
          props: true,
          // props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/puchase/view/:id",
          name: "view-purchase-request",
          component: () => import("@/views/modules/purchase/viewPurchase.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // sales
        {
          path: "/sales",
          name: "sales",
          component: () => import("@/views/modules/sales/sales.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/sales/add-sales",
          name: "add-sales",
          component: () => import("@/views/modules/sales/addSales.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/sales/edit-sales/:id",
          name: "edit-sales",
          component: () => import("@/views/modules/sales/editSales.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/sales/view/:id",
          name: "view-sales",
          component: () => import("@/views/modules/sales/viewSales.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        */
        /**
         * Deprecated END
         */

        // Recoil
        {
          path: "/recoil",
          name: "recoil",
          component: () => import("@/views/modules/recoil/Recoil.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/recoil/add-recoil",
          name: "add-recoil",
          component: () => import("@/views/modules/recoil/addRecoil.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/recoil/edit-recoil/:id",
          name: "edit-recoil",
          component: () => import("@/views/modules/recoil/editRecoil.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/recoil/view/:id",
          name: "view-recoil",
          component: () => import("@/views/modules/recoil/viewRecoil.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        // Hardware
        {
          path: "/hardware",
          name: "hardware",
          component: () => import("../views/plant/Hardware.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // Station
        {
          path: "/station",
          name: "station",
          component: () => import("@/views/plant/Station.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // Instruction
        /**
         * Deprecated - not to be used
         */
        /*
        {
          path: "/instruction",
          name: "instruction",
          component: () => import("@/views/planning/Instruction.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        */
        /**
         * Deprecated END
         */
        // Reason LIst
        {
          path: "/reason-list",
          name: "reason-list",
          component: () => import("@/views/reasonlist/ReasonList.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // Pick List
        {
          path: "/update-pick-list-order/:id",
          name: "update-pick-list-order",
          component: () => import("@/views/picklist/UpdatePickListOrder.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        // Job Order
        {
          path: "/ongoing-job-order",
          name: "ongoing-job-order",
          component: () => import("@/views/joborder/Joborder"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/completed-job-order",
          name: "completed-job-order",
          component: () => import("@/views/joborder/completedJoborder"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/job-order/add-job-order",
          name: "add-job-order",
          component: () => import("@/views/joborder/addJobOrder.vue"),
          props: true,
          meta: {
            breadcrumb: [
              { text: "Job Order", to: "/job-order" },
              { text: "Add Job Order", active: true },
            ],
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        {
          path: "/job-order/view/:id",
          name: "job-order-view",
          component: () => import("@/views/joborder/detailJobOrder.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        // Role
        {
          path: "/role",
          name: "role",
          component: () => import("@/views/modules/role/Role.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/role/add",
          name: "role-add",
          component: () => import("@/views/modules/role/AddRole.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        {
          path: "/role/edit/:id",
          name: "role-edit",
          component: () => import("@/views/modules/role/EditRole.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        {
          path: "/role/view/:id",
          name: "role-view",
          component: () => import("@/views/modules/role/ViewRole.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },

        //Pause Setting pause-setting
        {
          path: "/pause-setting",
          name: "pause-setting",
          component: () => import("@/views/Pause/PauseSetting.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },

        // User
        {
          path: "/user",
          name: "user",
          component: () => import("@/views/modules/user/User.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/user/add",
          name: "add-user",
          component: () => import("@/views/modules/user/Add-User.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        {
          path: "/user/edit/:id",
          name: "edit-user",
          component: () => import("@/views/modules/user/Edit-User.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        // Note
        /**
         * Deprecated - note to be used
         */
        /*
        {
          path: "/note",
          name: "note",
          component: () => import("@/views/modules/note/note.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: false,
          },
        },

        
        {
          path: "/test",
          name: "test note",
          component: () => import("@/views/modules/note/test.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: false,
          },
        },

        // OEE
        {
          path: "/oee",
          name: "oee",
          component: () => import("@/views/modules/oee/OEE.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        */
        /**
         * Deprecated END
         */

        // directSales
        {
          path: "/direct-sales",
          name: "direct-sales",
          component: () =>
            import("@/views/modules/direct-sales/directSales.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/direct-sales/view/:id",
          name: "view-direct-sales",
          component: () =>
            import("@/views/modules/direct-sales/view-directSales.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        // packing
        {
          path: "/ongoing-packing",
          name: "ongoing-packing",
          component: () => import("@/views/modules/packing/ongoing-packing.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/completed-packing",
          name: "completed-packing",
          component: () => import("@/views/modules/packing/completed-packing.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        }, 
        // Delivery
        {
          path: "/ongoing-delivery-picking",
          name: "ongoing-delivery-picking",
          component: () => import("@/views/modules/delivery/ongoing-delivery.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/completed-delivery-picking",
          name: "completed-delivery-picking",
          component: () => import("@/views/modules/delivery/completed-delivery.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/ongoing-delivery-picking/process/:sonumber",
          name: "dlivery-picking-process",
          component: () => import("@/views/modules/delivery/process-delivery.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        // fetch
        {
          path: "/ongoing-drum-picking",
          name: "ongoing-drum-picking",
          component: () => import("@/views/modules/fetch/ongoing-drum-picking.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/completed-drum-picking",
          name: "completed-drum-picking",
          component: () => import("@/views/modules/fetch/completed-drum-picking.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        {
          path: "/drum-picking/view/:id",
          name: "view-fetch",
          component: () => import("@/views/modules/fetch/view-fetch.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        {
          path: "/drum-picking/view/:id/selectMachine",
          name: "select-machine",
          component: () => import("@/views/modules/fetch/selectMachine.vue"),
          props: true,
          meta: {
            rule: "editor",
            no_scroll: true,
            // requiresAuth: true,
          },
        },
        // fetch
        {
          path: "/hardware-testing",
          name: "hardware-testing",
          component: () =>
            import("@/views/modules/hardware-testing/S7Protocol.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // {
        //   path: "/machine-list/cutterHMI/:id",
        //   name: "cutter-HMI",
        //   component: () => import("@/views/modules/HMI/cutterHMI.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        {
          path: "/machine",
          name: "machine",
          component: () => import("@/views/modules/HMI/cutterHMIList.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
        // {
        //   path: "/machine-list/cutterHMI/:id/addOrderHMI",
        //   name: "add-order-HMI",
        //   component: () => import("@/views/modules/HMI/addOrderHMI.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        // {
        //   path: "/machine-list/cutterHMI/:id/verifMamaDrum",
        //   name: "verif-mama-drum",
        //   component: () => import("@/views/modules/HMI/verifMamaDrum.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        // {
        //   path: "/machine-list/cutterHMI/:id/mamaDrumSetup/:jobOrder",
        //   name: "load-drum",
        //   component: () => import("@/views/modules/HMI/loadDrum.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        // {
        //   path: "/machine-list/cutterHMI/:id/babyDrumSetup/:jobOrder",
        //   name: "load-baby-drum",
        //   component: () => import("@/views/modules/HMI/loadBabyDrum.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        // {
        //   path: "/machine-list/cutterHMI/:id/pickListLineProcessing/:jobOrder",
        //   name: "pick-list-line-process",
        //   component: () =>
        //     import("@/views/modules/HMI/pickListLineProcessing.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        // {
        //   path: "/machine-list/cutterHMI/:id/HMIprocess",
        //   name: "process-HMI",
        //   component: () => import("@/views/modules/HMI/processHMI.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        // {
        //   path: "/machine-list/cutterHMI/:id/HMIrecoilprocess",
        //   name: "process-recoil-HMI",
        //   component: () => import("@/views/modules/HMI/processRecoilHMI.vue"),
        //   props: true,
        //   meta: {
        //     rule: "editor",
        //     no_scroll: true,
        //     // requiresAuth: true,
        //   },
        // },
        {
          path: "/picklist-history",
          name: "picklist-history",
          component: () => import("@/views/picklist/pickList.vue"),
          meta: {
            rule: "editor",
            no_scroll: true,
            requiresAuth: true,
          },
        },
      ],
    },
    {
      path: "",
      component: () => import("../layouts/full/LayoutFull.vue"),
      children: [
        {
          path: "/error-404",
          name: "error-404",
          component: () => import("@/views/error/Error404.vue"),
          meta: {
            layout: "full",
          },
        },
        {
          path: "/pages/login",
          name: "login",
          component: () => import("@/views/login/Login.vue"),
          meta: {
            layout: "full",
          },
        },
        {
          path: "/not-authorized",
          name: "not-authorized",
          component: () => import("@/views/error/NotAuthorized.vue"),
          meta: {
            layout: "full",
          },
        },
        // {
        //   path: '/pages/register',
        //   name: 'register',
        //   component: () => import('@/views/login/Register.vue'),
        //   meta: {
        //     layout: 'full'
        //   },
        // },
      ],
    },
    {
      path: "*",
      redirect: "error-404",
    },
  ],
});

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})
router.beforeEach((to, from, next) => {
  // Check Password
  setTimeout(() => {
    var getActiveUser = store.getters['auth/getActiveUser'];

    var getLastUpdatedPassword = getActiveUser.props ? getActiveUser.props.passwordUpdatedAt ? new Date(getActiveUser.props.passwordUpdatedAt).getTime() : undefined : undefined;

    // if (getLastUpdatedPassword === undefined || getLastUpdatedPassword < 1663139421195) {
    //   next({
    //     path: '/change-password',
    //   })
    // }
  }, 1000)

  var permission = {
    allAccess: true
  };

  if (router.app.$session.get('phibase-permission')) {
    permission = domainPermission('Common');

    var userAccess = viewAccess();
    userAccess.push('profile');
    userAccess.push('change-password');
    userAccess.push('application');
  }

  if (to.matched.some(record => record.meta.requiresAuth)) {
    var path = to.path.split('/')

    if (!router.app.$session.exists()) {
      next({
        path: '/pages/login',
      })
    }
    if (!permission.allAccess && path.length > 0 && !userAccess.includes(path[1])) {
      next({
        path: '/not-authorized',
      })
    }

    next()
  } else {
    next()
  }
})

export default router 
