import axios from '@/axios.js'
import router from '@/router/index.js'

export default {
  getBabyDrum:({commit})=>{
    axios
      // .get('/common/items?app='+process.env.VUE_APP_APPLICATION_NAME)
      .get("/manufacture/master-data/baby-drum")
      .then((res) => {
        commit("GET_BABY_DRUM", res.data.data);
      })
      .catch((err) => {
        alert(err);
      });
  },
  
}