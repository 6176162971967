import state from './moduleNotifyState'
import mutations from './moduleNotifyMutations'
import actions from './moduleNotifyActions'
import getters from './moduleNotifyGetters'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
