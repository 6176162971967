import axios from '@/axios'
import router from '@/router/index.js'
import { dateFormat } from '@/utils/utils';

export default {

  //dashboard Commerce
  fetchQuickStats: ({ commit }) => {
    return axios
    .get('/commerce/core/getCommerceQuickStat/' + router.app.$session.get('phibase-app'))
    .then(res => {

      let date = []
      res.data.data.salesOrder.forEach(el => {
        date.push(dateFormat(el.createdAt).split(',')[0])
      })
      let groupByDate = {
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          // curve: 'straight'
          curve: 'smooth'
        },
        title: {
          text: '',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: [... new Set(date)],
        },
      }
      const count = {}

      date.map(element => {
        count[element] = (count[element] || 0) + 1
      });

      let sum = [{
        name: "quantitiy",
        data: Object.values(count)
      }]
      
      res.data.data.chartOptions = groupByDate
      res.data.data.series = sum

      let data = res.data.data
      let oldDate = data.chartOptions.xaxis.categories
      let oldData = data.series[0].data

      let startDate = new Date();
      startDate.setDate(1);

      // Tanggal akhir: Hari ini
      let endDate = new Date();

      // Fungsi untuk menghasilkan daftar tanggal antara dua tanggal
      function getDates(startDate, endDate) {
        let dates = [];
        let currentDate = startDate;
        while (currentDate <= endDate) {
          dates.push(new Date(currentDate));
          currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
      }

      // Panggil fungsi dan tampilkan hasilnya ke konsol
      let datesBetween = getDates(startDate, endDate);
      for (let i = 0; i < datesBetween.length; i++) {
        datesBetween[i].toLocaleDateString()
      }

      let dateConvert = []

      datesBetween.forEach(item => {
        let date = new Date(item)
        let getDate = (date.getDate()).toString().padStart(2,"0")
        let getMonth = (date.getMonth()+1).toString().padStart(2,"0")
        let getYear = date.getFullYear().toString().substring(2,4)
        dateConvert.push(`${getDate}/${getMonth}/${getYear}`)
      })

      let temp = []
      let i = 0

      dateConvert.forEach(item => {
        let getIndex = oldDate.indexOf(item)
        if(getIndex == -1){
          temp.push(0)
        }
        if(getIndex != -1){
          temp.push(oldData[i])
          i++
        }
      })
      data.series[0].data = temp
      data.chartOptions.xaxis.categories = dateConvert

      commit('GET_QUICK_STATS', data);
      return data
    })

    .catch(err => {
      alert(err);
    });
  },


  fetchOrderAverage: ({ commit }) => {
    return axios
    .get('/commerce/core/getCommerceOrderAverage/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_ORDER_AVERAGE', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchTopProducts: ({ commit }) => {
    return axios
    .get('/commerce/core/getCommerceTopProducts/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_TOP_PRDUCTS', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchLowStockMonitor: ({ commit }) => {
    return axios
    .get('/commerce/core/getCommerceLowStockMonitor/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_LOW_STOCK_MONITOR', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchActionable: ({ commit }) => {
    return axios
    .get('/commerce/core/getCommerceActionable/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_ACTIONABLE', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  //Dashboard Manufacture
  
  fetchManufactureQuickStats: ({ commit }) => {
    return axios
    .get('/manufacture/master-data/getManufactureQuickStat/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_MANUFACTURE_QUICKSTATS', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchManufactureOEE: ({ commit }) => {
    return axios
    .get('/manufacture/master-data/getManufactureOEE/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_MANUFACTURE_OEE', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchManufactureTopProduction: ({ commit }) => {
    return axios
    .get('/manufacture/master-data/getManufactureTopProduction/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_MANUFACTURE_TOP_PRODUCTION', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchManufactureAverage: ({ commit }) => {
    return axios
    .get('/manufacture/master-data/getManufactureAverageProcessTime/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_MANUFACTURE_AVERAGE', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

  fetchManufactureActionable: ({ commit }) => {
    return axios
    .get('/manufacture/master-data/getManufactureActionable/' + router.app.$session.get('phibase-app'))
    .then(res => {
      commit('GET_MANUFACTURE_ACTIONABLE', res.data.data);
      return res.data.data
      
    })

    .catch(err => {
      alert(err);
    });
  },

}
