import axios from "@/axios.js";
import { getApp } from "../../utils/utils";
export default {
  namespaced: true,
  state: {
    jobOrders: [],
    ongoingJob: [],
    completedJobOrders: [],
    directSales: [],
    jobOrderID: "",
    newJobOrder: "",
  },
  getters: {
    getJobOrder: (state) => {
      return state.jobOrders;
    },
    getOngoingJobOrder: (state) => {
      return state.ongoingJob;
    },
    getCompletedJobOrders: (state) => {
      return state.completedJobOrders;
    },
    getDirectSales: (state) => {
      return state.directSales;
    },
    getJobOrderId: (state) => {
      return state.jobOrderID;
    },
    getNewJobOrder: (state) => {
      return state.newJobOrder;
    }
  },
  mutations: {
    NEW_JOBORDER(state, data) {
      state.newJobOrder = data;
    },
    GET_JOBORDER(state, data) {
      state.jobOrders = data;
    },
    GET_ONGOINGJOBORDER(state, payload) {
      state.ongoingJob = payload;
    },
    GET_DIRECTSALE(state, payload) {
      state.directSales = payload;
    },
    GET_COMPLETED_JOBORDER(state, payload) {
      state.completedJobOrders = payload;
    },
    GET_JOBORDERID(state, data) {
      state.jobOrderID = data;
    },
    ADD_JOBORDER(state, payload) {
      state.jobOrders.push(payload);
    },
    PROCESS_JOBORDER(state, payload) {
      state.jobOrders.push(payload);
    },
    UPDATE_PROCESS_JOBORDER: (state, payload) => {
      let request = state.jobOrders.find((u) => u._id === payload._id);
      for (let [key, value] of Object.entries(payload)) {
        if (request[key] && request[key] !== value) {
          request[key] = value;
        }
      }
    },
    UPDATE_JOBORDER: (state, payload) => {
      let request = state.jobOrders.find((u) => u._id === payload._id);
      if (request !== undefined) {
        for (let [key, value] of Object.entries(payload)) {
          if (request[key] && request[key] !== value) {
            request[key] = value;
          }
        }
      }
    },
    APPROVE_DIRECTSALE: (state, payload) => {
      let request = state.directSales.find((u) => u._id === payload._id);
      if (request !== undefined) {
        for (let [key, value] of Object.entries(payload)) {
          if (request[key] && request[key] !== value) {
            request[key] = value;
          }
        }
      }
    },
    REJECT_DIRECTSALE: (state, payload) => {
      let request = state.directSales.find((u) => u._id === payload._id);
      if (request !== undefined) {
        for (let [key, value] of Object.entries(payload)) {
          if (request[key] && request[key] !== value) {
            request[key] = value;
          }
        }
      }
    },
  },
  actions: {
    getJobOrder({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/joborders?props[app]=" + getApp())
          .then((response) => {
            let { data } = response.data;
            commit("GET_JOBORDER", data);
            resolve(data);
          })
          .catch((e) => {
            reject(e);
            console.log(e);
          });
      });
    },
    getOngoingJobOrder({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/ongoing-joborders", {
            params: params
          })
          .then((res) => {
            console.log(res)
            commit("GET_ONGOINGJOBORDER", res.data.data);
            resolve(res.data);
          })
          .catch((e) => {
            reject(e);
            console.log(e);
          });
      });
    },
    getCompletedJobOrders({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/completed-joborders", {
            params: params
          })
          .then((res) => {
            commit("GET_COMPLETED_JOBORDER", res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getDirectSales({ commit }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get("manufacture/master-data/direct-sales", {
            params: params
          })
          .then((res) => {
            commit("GET_DIRECTSALE", res.data.data);
            resolve(res.data);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    approveJobOrder({ commit }, { id, data }) {
      return axios
        .put(`manufacture/master-data/joborder/${id}`, data)
        .then((response) => {
          response.data.data.status = data.status;
          commit("UPDATE_JOBORDER", { data: response.data.data });
          // return response.data;
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    },
    rejectJobOrder({ commit }, { id, data }) {
      return axios
        .put(`manufacture/master-data/joborder/${id}`, data)
        .then((response) => {
          response.data.data.status = data.status;
          commit("UPDATE_JOBORDER", { data: response.data.data });
          // return response.data;
        })
        .catch((e) => {
          console.log(e);
          throw e;
        });
    },
    approveDirectSale: ({ commit }, { id, data }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/direct-sale/${id}`, data)
          .then((res) => {
            commit("APPROVE_DIRECTSALE", res.data.data);
            resolve(res.data.data);
          })
          .catch((err) => reject(err));
      }),
    rejectDirectSale({ commit }, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/reject-direct-sale/${id}`, data)
          .then((res) => {
            // commit("REJECT_DIRECTSALE", res.data);
            resolve(res.data);
          })
          .catch((err) => {
            console.log(err);
            reject(err)
          });
      })
    },
    getJobOrderId({ commit }, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`manufacture/master-data/joborder/${id}`)
          .then((res) => {
            commit("GET_JOBORDERID", res.data.data);
            resolve(res.data.data);
          })
          .catch((err) => {
            reject(err)
          });
      });
    },
    jobOrderTimestamp({ commit }, { id, timestamp }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/joborder/timestamp/${id}`, {
            timestamp: timestamp,
          })
          .then((response) => {
            let { data } = response.data;
            commit("GET_JOBORDERID", data);
            resolve(data);
          })
          .catch((e) => {
            console.log(e);
          });
      });
    },
    updateJobOrder: ({ commit }, { id, data }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`/manufacture/master-data/joborder/${id}`, data)
          .then((res) => {
            commit("UPDATE_JOBORDER", res.data.data);
            resolve(res.data.data);
          })
          .catch((err) => reject(err));
      }),
    getJobOrderItem({ commit }, idItem) {
      return axios
        .get(`manufacture/master-data/item/${idItem}`)
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          console.log(e);
          return null;
        });
    },
    submitJobOrder(context, order) {
      return axios
        .post(`manufacture/master-data/workorder/joborder/submit`, order)
        .then((result) => {
          return result.data;
        });
    },
    addJobOrder: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post("manufacture/master-data/joborders/add", payload)
          .then((res) => {
            commit("ADD_JOBORDER", res.data.data);
            commit("NEW_JOBORDER", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      }),
    processJobOrder: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        axios
          .post("manufacture/master-data/joborders/process", payload)
          .then((res) => {
            commit("PROCESS_JOBORDER", res.data.data);
            resolve();
          })
          .catch((err) => reject(err));
      }),
    updateProcessJobOrder: ({ commit }, { id, data }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/joborders/process/${id}`, data)
          .then((res) => {
            commit("UPDATE_PROCESS_JOBORDER", res.data);
            resolve();
          })
          .catch((err) => reject(err));
      }),
    cencelPicklistJobOrder: ({ commit }, { id, data }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/job-picklist-cencel/${id}`, data)
          .then((res) => {
            console.log(res);
            // commit("UPDATE_PROCESS_JOBORDER", res.data);
            resolve();
          })
          .catch((err) => reject(err));
      }),
    addPicklistJobOrder: ({ commit }, { id, data }) =>
      new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/job-picklist-add/${id}`, data)
          .then((res) => {
            // commit("UPDATE_PROCESS_JOBORDER", res.data);
            resolve();
          })
          .catch((err) => reject(err));
      }),
    continueRecoil: ({ commit }, payload) => 
      new Promise((resolve, reject) => {
        axios
          .post("manufacture/master-data/continuerecoil", payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      })
    ,
    becameRecoil: ({ commit }, payload) => 
      new Promise((resolve, reject) => {
        axios
          .post("manufacture/master-data/becamerecoil", payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      }),
    createJobOrderandFetching: ({ commit }, payload ) => 
      new Promise((resolve, reject) => {
        axios
          .post("/manufacture/master-data/create-joborder-fetching", payload)
          .then((res) => {
            resolve(res.data)
          })
          .catch((err) => reject(err))
      }),
    acceptMamaDrum: ({ commit }, { id }) => 
      new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/joborder/verif-mama-drum/${id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      }),
    rejectMamaDrum: ({ commit }, payload) => 
      new Promise((resolve, reject) => {
        axios
          .post("manufacture/master-data/joborder/reject-mama-drum", payload)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
      }),
    cancelJobOrder: ({ commit }, { id }) => 
      new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/cancel-joborder/${id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
    endJobOrder: ({ commit }, { id }) => 
      new Promise((resolve, reject) => {
        axios
          .put(`manufacture/master-data/end-joborder/${id}`)
          .then((res) => {
            resolve(res.data);
          })
          .catch((err) => reject(err));
    }),
  },
};
