export default {
  SET_MODAL_PICKING(state, payload) {
    state.notifyModalPicking = payload;
  },
  SET_MODAL_DELIVERY(state, payload) {
    state.notifyModalDelivery = payload;
  },
  SET_MODAL_FETCHING(state, payload) {
    state.notifyModalFetching = payload;
  },
  SET_INTERVAL_PICKING(state, payload) {
    state.npdid = payload;
  },
  SET_INTERVAL_DELIVERY(state, payload) {
    state.nddid = payload;
  },
  SET_INTERVAL_FETCHING(state, payload) {
    state.nfdid = payload;
  },
  GET_PICKING_DATA(state,payload){
    if(payload.length>0){
      state.notifyModalPicking=true;
      state.notifyPickingData=payload[0];
    }
    
  },
  GET_DELIVERY_DATA(state,payload){
    if(payload.length>0){
      state.notifyModalDelivery=true;
      state.notifyDeliveryData=payload[0];
    }
    
  },
  GET_FETCHING_DATA(state,payload){
    if(payload.length>0){
      state.notifyModalFetching=true;
      state.notifyFetchingData=payload[0];
    }
    
  },
}