export default {
    
    FETCH_REQUEST_SALES: (state, payload) => {
      state.requestSales = payload
    }, 
    FETCH_REQUEST_PURCHASE: (state, payload) => {
      state.requestPurchase = payload
    }, 
    FETCH_REQUEST: (state, payload) => {
      state.request = payload
    },
    ADD_REQUEST: (state, payload) => {
      state.request.push(payload)
    },
    UPDATE_REQUEST: (state, payload) => {
      let request = state.request.find(u => u._id === payload._id)
      for (let [key, value] of Object.entries(payload)) {
        if (request[key] && request[key] !== value) {
          request[key] = value
        }
      }
    },
    DELETE_REQUEST: (state, payload) => {
      let i = state.request.findIndex(u => u._id === payload)
      state.request.splice(i, 1)
    }
  }