// axios
import axios from 'axios'

// Base URL
const baseURL= `${document.location.origin}/api/v1`
// const baseURL = process.env.VUE_APP_AXIOS_URL ||'https://localhost:3000/api/v1'


export default axios.create({
  baseURL,
})

