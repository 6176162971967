import axios from '@/axios.js'
import router from '@/router/index.js'

export default {
  fetchOrder: ({ commit }) => {
    new Promise((resolve, reject) => {
      const app = router.app.$session.get('phibase-app')
      axios
        .get('/commerce/core/orders?props[app]='+ app)
        .then(res => {
          let filterByApp = res.data.data.filter( e=>{
            return e.props.app === router.app.$session.get('phibase-app')
          })

          commit('FETCH_ORDER', filterByApp)
          
          var filterSales = filterByApp.filter(x=>{
            return x.type==='Sales'
          })
          var filterPurchase = filterByApp.filter(x=>{            
            return x.type==='Purchase'
          })
          var filterRecoil = filterByApp.filter(x=>{            
            return x.type==='Recoil'
          })
          commit('FETCH_ORDER_SALES', filterSales)
          commit('FETCH_ORDER_PURCHASE', filterPurchase)
          commit('FETCH_RECOIL', filterRecoil)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  getById: ({ commit }, { id }) => {
      return axios
        .get(`/commerce/core/orders/${id}`)
        .then((res) => {

          let  {data}  = res.data;
          commit('GET_ORDER_ID', data); 
      }).catch((e) => {
        reject(err);
      });
  },
  addOrder: ({ commit }, payload) => new Promise((resolve, reject) => {
    
    payload.item = payload.item.map(x=>{
      return {
        ...x,
        detail: x.detail.id,
        drum : x.drum.id,
      }
    })
    axios
      .post('/commerce/core/orders', payload)
      .then(res => {
        commit('ADD_ORDER', res.data.data)
        resolve()
      })
      .catch(err => reject(err))
  }),
  updateOrder: ({ commit }, { id, data }) => new Promise((resolve, reject) => {
    axios
      .put(`/commerce/core/orders/${id}`, data)
      .then(res => {
        commit('UPDATE_ORDER', res.data.data)
        resolve()
      })
      .catch(err => reject(err))
  }),
  deleteOrder: ({ commit }, { id }) => new Promise((resolve, reject) => {
    axios
      .delete(`/commerce/core/orders/${id}`)
      .then(() => {
        commit('DELETE_ORDER', id)
        resolve()
      })
      .catch(err => reject(err))
  }),
}