export default {
  quickStats: [],
  orderAverage: [],
  topProducts: [],
  lowStockMonitor: [],
  actionable: [],
  manufactureQuickStats:[],
  manufactureOEE:[],
  manufactureTopProduction:[],
  manufactureAverage:[],
  manufactureActionable:[],
}
