import axios from '@/axios.js'
import {getApp} from '../../utils/utils';
export default {
  namespaced: true,
  state: {
    delivery:[],
  },
  getters: {
    getDelivery:state=>{
      return state.delivery;
    },
  },
  mutations: {
    GET_DELIVERY(state,payload){
      state.delivery = payload
    },
  },
  actions: {
    //Used to get all data
    getDelivery({ commit }, params){
      return new Promise((resolve, reject) => {
      axios
          .get('/manufacture/master-data/delivery?props[app]='+getApp(), {params: params,})
          .then(res => {
            commit('GET_DELIVERY',res.data.data)
            resolve(res.data)
          })
          .catch(err => {
            alert(err);
            reject(err)
          });
      })
    },
  }
}
