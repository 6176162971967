import axios from "@/axios.js";
export default {
  namespaced: true,
  state: {
    dashboardMachine: [],
    dashboardMachineToday: [],

  },
  mutations: {
    SET_MACHINES(state, machines) {
      state.machines = machines;
    },
    GET_MACHINES(state, payload) {
      state.dashboardMachine = payload
    },
    GET_MACHINES_TODAY(state, payload) {
      state.dashboardMachineToday = payload
    }
  },
  getters: {
    getDashboardMachine: state => state.dashboardMachine,
    getDashboardMachineToday: state => state.dashboardMachineToday
  },
  actions: {
    getDashboard({commit}){
      return new Promise((resolve, reject) => {
        axios
          .get(`/manufacture/master-data/dashboard-machine`)
          .then((res) => {
            commit("GET_MACHINES", res.data.data);
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    },
    getDashboardToday({commit}){
      return new Promise((resolve, reject) => {
        axios
          .get(`/manufacture/master-data/dashboard-machine-today`)
          .then((res) => {
            commit("GET_MACHINES_TODAY", res.data.data);
            resolve(res.data.data)
          })
          .catch((err) => {
            reject(err)
          })
      })
    }
  }
}