export default {
  GET_QUICK_STATS: (state, payload) => {
    state.quickStats = payload  
  },

  GET_ORDER_AVERAGE: (state, payload) => {
    state.orderAverage = payload
  },

  GET_TOP_PRDUCTS: (state, payload) => {
    state.topProducts = payload
  },

  GET_LOW_STOCK_MONITOR: (state, payload) => {
    state.lowStockMonitor = payload
  },

  GET_ACTIONABLE: (state, payload) => {
    state.actionable = payload
  },

  GET_MANUFACTURE_QUICKSTATS: (state, payload) => {
    state.manufactureQuickStats = payload
  },

  GET_MANUFACTURE_OEE: (state, payload) => {
    state.manufactureOEE = payload
  },

  GET_MANUFACTURE_TOP_PRODUCTION: (state, payload) => {
    state.manufactureTopProduction = payload
  },

  GET_MANUFACTURE_AVERAGE: (state, payload) => {
    state.manufactureAverage = payload
  },

  GET_MANUFACTURE_ACTIONABLE: (state, payload) => {
    state.manufactureActionable = payload
  },

}
